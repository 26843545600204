import Layout from "../../containers/Layout";
import { Grid, Skeleton, Box } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import CardDetail from "../commons/CardDetail/CardDetail";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "@chakra-ui/react";

const Materials = ({ materiales, fetchMoreData, hasMore, page }) => {
  const { type } = useParams();
  const materialesFiltrados = materiales.filter(
    (material) => material.tipo === type
  );

  const isLoading = !materiales || materiales.length === 0;

  return (
    <Layout pt={16}>
      {isLoading ? (
        <Grid
          templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
          gap={6}
          justifyContent="space-around"
        >
          {Array(9)
            .fill(0)
            .map((_, index) => (
              <Box key={index} p={4}>
                <Skeleton height="200px" width="100%" />
              </Box>
            ))}
        </Grid>
      ) : type ? (
        <InfiniteScroll
          dataLength={materiales.length}
          next={() => fetchMoreData(page)}
          hasMore={hasMore}
          loader={
            <div style={{ textAlign: "center", padding: "20px" }}>
              <Spinner size="xl" color="blue.500" />
            </div>
          }
        >
          <Grid
            templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
            gap={6}
            justifyContent="space-around"
          >
            {materialesFiltrados.map((material) => (
              <CardDetail material={material} key={material._id} />
            ))}
          </Grid>
        </InfiniteScroll>
      ) : (
        <InfiniteScroll
          dataLength={materiales.length}
          next={() => fetchMoreData(page)}
          hasMore={hasMore}
          loader={
            <div style={{ textAlign: "center", padding: "20px" }}>
              <Spinner size="xl" color="blue.500" />
            </div>
          }
        >
          <Grid
            templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
            gap={6}
            justifyContent="space-around"
          >
            {materiales.map((material) => (
              <CardDetail material={material} key={material._id} />
            ))}
          </Grid>
        </InfiniteScroll>
      )}
    </Layout>
  );
};

export default Materials;
