import { Text } from "@chakra-ui/react";
import Layout from "../../containers/Layout";
import VideoTemplate from "../VideoTemplate/VideoTemplate";

const mainTextContent = (
  <>
    <p style={{ marginBottom: "20px" }}>
      - For current students, please choose a date and time that works best for
      you using the calendar below, and I will send you a confirmation email.
    </p>
    <p style={{ marginBottom: "20px" }}>
      - For new students, you can join my newsletter to get notified for group
      lessons or when I have availability.
    </p>
  </>
);

const GetStarted = () => {
  return (
    <Layout mt={25}>
      <Text>
        {/* Book a trial lesson with me and start talking spanish today.   */}
      </Text>
      <VideoTemplate
        imageUrl="/images/calander_img 2.png"
        imageAlt="Descripción alternativa de la imagen"
        mainTitle="Let´s talk."
        subTitle="Booking made easy."
        mainText={mainTextContent}
        buttonLabel="Calendar"
        linkPath="https://calendly.com/spanishwithalex"
        showButton={true}
      />
    </Layout>
  );
};

export default GetStarted;
