import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";
import Layout from "../../containers/Layout";
import HeadingSection from "../commons/HeadingSection/HeadingSection";
import CustomButton from "../commons/CustomButton/CustomButton";
import { Link } from "react-router-dom";

const Video = () => {
  return (
    <Layout>
      <Flex
        direction={{ base: "column", xl: "row" }}
        align={{ base: "center", xl: "flex-start" }}
        justify={{ base: "center", xl: "space-between" }}
      >
        <Center w={{ base: "100%", xl: "50%" }} mb={{ base: 5, xl: 0 }}>
          <Image
            src="\images\calander_img 2.png"
            alt="Imagen de Alex"
            mx={{ base: "auto", lg: "0" }}
          />
        </Center>
        <Flex
          direction={"column"}
          pl={{ base: 2, md: 20, lg: 20 }}
          pr={{ base: 2, md: 0 }}
          pt={{ base: 8, md: 0 }}
          textAlign={"left"}
          w={{ xl: "50%" }}
        >
          <HeadingSection
            title="HOLA AMIGOS"
            sub="Mi escuela es tu
            escuela "
          />
          <Text mb={6}>
            ¡Hola! Learn Spanish your way with Alex Rodríguez! 1:1 lessons, free
            resources, and inmersion programs in Galicia. Fun, flexible, and a
            study plan designed for you. Book your free taster now! Nos vemos en
            clase.
            <p> ❤</p>
          </Text>
          <Box textAlign={{ base: "center", xl: "center" }}>
            <Link to="why-me">
              <CustomButton label="Why me?" />
            </Link>
          </Box>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default Video;
