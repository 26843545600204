import { Flex, Heading, Image, Text } from "@chakra-ui/react";
import Layout from "../../containers/Layout";
import HeadingSection from "../commons/HeadingSection/HeadingSection";
import CustomButton from "../commons/CustomButton/CustomButton";
import { FaDiscord, FaHeart, FaTelegram } from "react-icons/fa";
import { Link } from "react-router-dom";

const HorizontalCardIlustrations = () => {
  return (
    <Layout>
      <Flex
        mb="8"
        direction={"column"}
        alignItems={"center"}
        textAlign={"center"}
      >
        <HeadingSection title="" sub="Anytime, Anywhere." />
      </Flex>
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        justifyContent={{ base: "center", md: "space-between" }}
      >
        <Flex
          width={{ base: "100%", md: "30%" }}
          mb={12}
          direction={"column"}
          alignItems={"center"}
          textAlign={"center"}
          gap={4}
        >
          <Image src={"/images/Illustration_1.svg"} alt="" width={224} />
          <Heading fontSize="2xl">Tu escuela</Heading>
          <FaHeart />
          <Text>
            Free and exclusive content for all levels to boost your Spanish
            fast!
          </Text>
        </Flex>
        <Flex
          width={{ base: "100%", md: "30%" }}
          mb={12}
          direction={"column"}
          alignItems={"center"}
          textAlign={"center"}
          gap={4}
        >
          {/* <Image src={"/images/Illustration_2.svg"} alt="" width={224} />
          <Heading fontSize="2xl"></Heading>
          <FaDiscord /> */}
          {/* <Text>
            Discord is a communication platform that provides open talkrooms to
            connect with a community of Spanish learners.
          </Text> */}
        </Flex>
        <Flex
          width={{ base: "100%", md: "30%" }}
          direction={"column"}
          alignItems={"center"}
          textAlign={"center"}
          gap={4}
        >
          <Image src={"/images/Illustration_3.svg"} alt="" width={224} />
          <Heading fontSize="2xl">Tu Telegram</Heading>
          <FaTelegram />
          <Text>
            Join the rest of my students and stay updated with tips, news, and
            the latest—all on your phone!
          </Text>
        </Flex>
      </Flex>
      <Flex
        pt={{ base: "8", md: "0" }}
        direction={"column"}
        alignItems={"center"}
      >
        <Link to="/get-started">
          <CustomButton label="Join Telegram Group" />
        </Link>
      </Flex>
    </Layout>
  );
};

export default HorizontalCardIlustrations;
