import {
  Avatar,
  Box,
  Button,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  CloseIcon,
  HamburgerIcon,
  MoonIcon,
  SunIcon,
} from "@chakra-ui/icons";
import { useTheme } from "@emotion/react";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";

const MobileNavItem = ({ label, children, to, onClose }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4}>
      <Box
        py={2}
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          textDecoration: "none",
          color: "red",
        }}
        _focus={{
          outline: "none",
        }}
        display="flex"
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
          onClick={() => {
            if (label === "Materiales" || "Login" || "Buy Lessons") {
              onClose();
            }
          }}
        >
          <Link to={to}>{label}</Link>
        </Text>
        {children && (
          <Icon
            onClick={children && onToggle}
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
            ml="auto" // Push the icon to the right
          />
        )}
      </Box>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Box key={child.label} py={2}>
                <Link
                  to={child.to}
                  onClick={onClose} // Close the menu when a submenu item is clicked
                >
                  {child.label}
                </Link>
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const DesktopNav = ({ navItems, colors }) => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("red", "red");
  const popoverContentBgColor = useColorModeValue(
    "white",
    colors.dark.bgSecondary
  );

  return (
    <Stack direction={"row"} spacing={4}>
      {navItems.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Box
                p={2}
                fontSize={"sm"}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                <Link to={navItem.to}>{navItem.label}</Link>
              </Box>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"auto"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, to, subLabel }) => {
  return (
    <Box
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("gray.50", "gray.900") }}
    >
      <Link to={to}>
        <Stack direction={"row"} align={"center"}>
          <Box>
            <Text
              transition={"all .3s ease"}
              _groupHover={{ color: "red" }}
              fontWeight={500}
            >
              {label}
            </Text>
            {/* <Text fontSize={"sm"}>{subLabel}</Text> */}
          </Box>
          <Flex
            transition={"all .3s ease"}
            transform={"translateX(-10px)"}
            opacity={0}
            _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
            justify={"flex-end"}
            align={"center"}
            flex={1}
          >
            <Icon color={"red"} w={5} h={5} as={ChevronRightIcon} />
          </Flex>
        </Stack>
      </Link>
    </Box>
  );
};

const MobileNav = ({ navItems, colors, onClose }) => {
  return (
    <Stack
      bg={useColorModeValue("white", colors.dark.bgSecondary)}
      p={4}
      display={{ md: "none" }}
    >
      {navItems.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} onClose={onClose} />
      ))}
    </Stack>
  );
};

const Navbar = ({ materialesTotales }) => {
  console.log("aparecen:", materialesTotales);
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const { isOpen, onToggle } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();
  const { colors } = useTheme();
  const uniqueTypes = new Set();
  const newArray = materialesTotales
    .filter((item) => {
      if (!uniqueTypes.has(item.tipo)) {
        uniqueTypes.add(item.tipo);
        return true;
      }
      return false;
    })
    .map((item) => ({
      label: item.tipo.charAt(0).toUpperCase() + item.tipo.slice(1),
      subLabel: item.tipo,
      to: `/materiales/${item.tipo.toLowerCase()}`,
    }));
  console.log("en const Navbar: ", newArray);
  const navItems = [
    {
      label: "Materiales",
      to: "/materiales",
      children: newArray,
    },
    {
      label: "Login",
      to: "/login",
    },
    {
      label: "Buy Lessons",
      to: "/shop",
    },
    {
      label: "Inmersion",
      to: "/inmersion-program",
    },
  ];

  const logout = () => {
    setUser({});
    navigate("/");
    window.location.reload();
  };
  console.log("user info:", user);
  return (
    <Box position="fixed" width="100%" zIndex={3}>
      <Box
        position="fixed"
        width="100%"
        zIndex={2}
        style={{
          backdropFilter: useColorModeValue("blur(6px)", "blur(6px)"),
        }}
      >
        <Flex
          bg={useColorModeValue(
            colors.light.bgTranspLight,
            colors.dark.bgTranspDark
          )}
          color={useColorModeValue("gray.600", "white")}
          minH={"60px"}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.900")}
          align={"center"}
        >
          <Flex
            flex={{ base: 1, md: "auto" }}
            ml={{ base: -2 }}
            display={{ base: "flex", md: "none" }}
          >
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>
          <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
            <Link to="/">
              <img
                src={"/images/BanderitaEspañaTampon.png"}
                alt="logo"
                width={"78px"}
                height="auto"
              />
            </Link>
            <Flex display={{ base: "none", md: "flex" }} ml={10}>
              <DesktopNav navItems={navItems} colors={colors} />
            </Flex>
          </Flex>
          <Stack
            flex={{ base: 1, md: 0 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={1}
          >
            <Menu>
              <Button onClick={toggleColorMode} bg={"transparent"}>
                {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
              </Button>
              <MenuButton
                as={Button}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
                minW={0}
              >
                {/* <Popover trigger={"hover"}>
                  <PopoverTrigger>
                    <Button>Triger</Button>
                    <Avatar size={"sm"} ml={2} src={user?.picture} />
                  </PopoverTrigger>
                  <PopoverContent>
                    <Stack>Logout</Stack>
                    <Stack>LogIN</Stack>
                  </PopoverContent>
                </Popover> */}
              </MenuButton>
              {/* <Button onClick={logout}>Logout</Button> */}
            </Menu>

            <Popover trigger={"hover"} placement="top-start">
              <PopoverTrigger>
                <Button>
                  <Avatar size={"sm"} ml={2} src={user?.picture} />
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverHeader fontWeight="semibold">
                  Your profile
                </PopoverHeader>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  {user?.picture ? (
                    <Button onClick={logout}>Logout</Button>
                  ) : (
                    <Link to={"/login"}>Login</Link>
                  )}
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Stack>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <MobileNav navItems={navItems} colors={colors} onClose={onToggle} />
        </Collapse>
      </Box>
    </Box>
  );
};

export default Navbar;
