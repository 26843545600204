import { Card, CardBody, CardFooter, Flex } from "@chakra-ui/react";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import VideoTemplate from "../VideoTemplate/VideoTemplate";
import { UserContext } from "../../contexts/UserContext";
import BoxInfoTemplate from "../BoxInfoTEmplate/BoxInfoTemplate";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";

const Material = () => {
  const { type, nombre } = useParams();
  const [material, setMaterial] = useState({});
  const { user, setUser } = useContext(UserContext);

  const postData = async (data) => {
    try {
      const response = await axios.post("/api/login", data);
      const decoded = jwtDecode(response.data);
      setUser(decoded);
      console.log(decoded);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    axios
      .get(`/api/materials/${type}/${nombre}`)
      .then((res) => {
        console.log(res);

        setMaterial(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [nombre, type]);
  return (
    <>
      <Card>
        <CardBody pt={16}>
          {!material.premium || (material.premium && user?.name) ? (
            <>
              {" "}
              <Flex alignItems="center" justifyContent="center">
                {/* <Flex flex="1" justifyContent="center">
      {material.tipo === "podcast" && <PiApplePodcastsLogo color="lightblue" fontSize={"5.5em"} />}
      {material.tipo === "video" && <IoPlayCircle color="red" fontSize={"5.5em"} />}
      {material.tipo === "gramaticas" && <PiTextAlignLeftFill color="yellow" fontSize={"5.5em"} />}
    </Flex> */}
                <VideoTemplate
                  videoUrl={material.urlContenido}
                  // imageUrl={material.titulo}
                  // imageAlt="Descripción alternativa de la imagen"
                  mainTitle={material.titulo}
                  subTitle=""
                  mainText={material.descripcion}
                  buttonLabel="Join Telegram"
                  linkPath="https://t.me/+S78OquqBTZdmNGNk"
                  showButton={true}
                  secondaryText={material.contenidoMaterial}
                />
              </Flex>
            </>
          ) : (
            <Flex
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <BoxInfoTemplate
                title="..."
                mainText="Login to enjoy this content."
                iconType="redDot"
                borderColor="orange"
                showQuotes={true}
              />
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  const decoded = jwtDecode(credentialResponse?.credential);
                  const { email, name, picture } = decoded;
                  postData({ email, name, picture });
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </Flex>
          )}
        </CardBody>
        <CardFooter
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        ></CardFooter>
      </Card>
    </>
  );
};

export default Material;
