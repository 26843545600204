import { Card, CardBody, CardFooter, Text } from "@chakra-ui/react";
import {
  FaRegCircle,
  FaThermometerEmpty,
  FaThermometerQuarter,
  FaThermometerHalf,
  FaThermometerFull,
  FaStar,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const getThermometerColor = (nivel) => {
  if (nivel === "A1" || nivel === "A2") return "green";
  if (nivel === "B1" || nivel === "B2") return "orange";
  if (nivel === "C1" || nivel === "C2") return "red";
  return "gray";
};

const renderThermometer = (nivel) => {
  switch (nivel) {
    case "A1":
    case "A2":
      return (
        <FaThermometerQuarter
          style={{ color: getThermometerColor(nivel), fontSize: "1.5rem" }}
        />
      );
    case "B1":
    case "B2":
      return (
        <FaThermometerHalf
          style={{ color: getThermometerColor(nivel), fontSize: "1.5rem" }}
        />
      );
    case "C1":
    case "C2":
      return (
        <FaThermometerFull
          style={{ color: getThermometerColor(nivel), fontSize: "1.5rem" }}
        />
      );
    default:
      return (
        <FaThermometerEmpty style={{ color: "gray", fontSize: "1.5rem" }} />
      );
  }
};

const CardDetail = ({ material }) => {
  return (
    <Link to={`/materiales/${material.tipo}/${material.urlTitulo}`}>
      <Card
        _hover={{
          img: {
            filter: "brightness(50%)",
            transition: "filter 0.2s ease",
          },
        }}
      >
        <CardBody padding={0}>
          <img
            src={material.urlImagen}
            alt=""
            style={{ width: "100%", transition: "filter 0.5s ease" }}
          />
        </CardBody>
        <CardFooter
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {renderThermometer(material.nivel)}
            {material.premium && (
              <FaStar style={{ marginLeft: "8px", color: "" }} />
            )}
            <Text style={{ margin: "0 auto" }}>{material.titulo}</Text>
          </div>
        </CardFooter>
      </Card>
    </Link>
  );
};

export default CardDetail;
