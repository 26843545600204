import { AspectRatio, Box, Flex, Text } from "@chakra-ui/react";
import Layout from "../../containers/Layout";
import HeadingSection from "../commons/HeadingSection/HeadingSection";
import CustomButton from "../commons/CustomButton/CustomButton";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const DualTextVideoTemplate = ({
  videoUrl,
  mainTitle,
  leftText,
  rightText,
  linkPath,
  showButton = true,
  buttonLabel = "Learn More", // Valor por defecto es "Learn More"
}) => {
  useEffect(() => {
    // Asegúrate de que la página se desplaza hacia arriba al cargar el componente
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Flex direction="column" align="center" justify="center" w="100%">
        <HeadingSection title={mainTitle} />

        <Flex
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          w="100%"
          mb={8}
          px={{ base: 4, md: 20 }}
        >
          <Box flex="1" mb={{ base: 4, md: 0 }} pr={{ md: 4 }}>
            <Text textAlign="left">{leftText}</Text>
          </Box>
          <Box flex="1" pl={{ md: 4 }}>
            <Text textAlign="left">{rightText}</Text>
          </Box>
        </Flex>

        <Box
          width="100%"
          height="100"
          paddingBottom="95.25%"
          position="relative"
        >
          <AspectRatio ratio={1}>
            <iframe
              title="video"
              src={videoUrl}
              loading="lazy"
              scrolling="no"
              style={{
                border: 0,
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
              allowFullScreen
            ></iframe>
          </AspectRatio>
        </Box>

        {showButton && (
          <Box textAlign="center" mt={4}>
            <Link to={linkPath}>
              <CustomButton label={buttonLabel} />
            </Link>
          </Box>
        )}
      </Flex>
    </Layout>
  );
};

export default DualTextVideoTemplate;
