import { useState, useEffect, useContext } from "react";

import Main from "./components/Main/Main";

import Navbar from "./components/Navbar/Navbar";

import axios from "axios";

import { Route, Routes } from "react-router-dom";

import Materials from "./components/Materials/Materials";

import Material from "./components/Material/Material";

import Footer from "./components/Footer/Footer";

import Shop from "./components/Shop/Shop";

import { Flex } from "@chakra-ui/react";

import Login from "./components/Login/Login";

import GetStarted from "./components/GetStarted/GestStarted";

import BookLesson from "./components/BookLesson/BookLesson";

import Subscribe from "./components/Subscribe/Subscribe";

import { UserContext } from "./contexts/UserContext";

import SuccessPaymentPage from "./components/SuccessPaymentPage/SuccessPaymentPage";

import SuccessSubscriptionPage from "./components/SuccessSubscriptionPage/SuccessSubscriptionPage";

import Cancel from "./components/Cancel/Cancel";

import Inmersion from "./components/Inmersion/Inmersion";

import WhatStudentsSay from "./components/WhatStudentsSay/WhatStudentsSay";
import WhyMe from "./components/WhyMe/WhyMe";

function App() {
  const [materiales, setMateriales] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [materialesTotales, setMaterialesTotales] = useState([]);

  const { user, setUser } = useContext(UserContext);

  // useEffect(() => {
  //   axios

  //     .get(`/api/materials?page=${page}`)
  //     .then((res) => {
  //       const newItems = res.data;
  //       setItems((prevItems) => [...prevItems, ...newItems]);
  //       setPage(page + 1);
  //       if (newItems.length === 0) setHasMore(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   const iat = user?.iat;

  //   const exp = user?.exp;

  //   const now = Date.now() / 1000;

  //   if (iat && exp && now > exp) {
  //     setUser({});
  //   }
  // }, [user, setUser]);

  // useEffect(() => {
  //   fetchMoreData(1);
  // }, []);

  // const fetchMoreData = (currentPage) => {
  //   axios
  //     .get(`/api/materials?page=${currentPage}`)
  //     .then((res) => {
  //       const newItems = res.data;
  //       setTimeout(() => {
  //         setItems((prevItems) => [...prevItems, ...newItems]);
  //       }, 1500);

  //       setPage((prevPage) => prevPage + 1);
  //       if (newItems.length === 0) setHasMore(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   // Verificación de expiración de sesión
  //   const iat = user?.iat;
  //   const exp = user?.exp;
  //   const now = Date.now() / 1000;

  //   if (iat && exp && now > exp) {
  //     setUser({});
  //   }
  // };

  const fetchMateriales = async () => {
    try {
      const res = await axios.get(`/api/materials?page=${page}`);
      const newMateriales = res.data.result;
      console.log("result data: ", res.data.result);
      setMateriales((prev) => [...prev, ...newMateriales]);
      setPage((prev) => prev + 1);
      setHasMore(res.data.hasMore);
    } catch (err) {
      console.error("Error fetching materials:", err);
    }
  };

  const fetchMaterialesTotales = async () => {
    try {
      const res = await axios.get(`/api/materials/totales`);
      setMaterialesTotales(res.data);
    } catch (err) {
      console.error("Error fetching materials:", err);
    }
  };

  useEffect(() => {
    // Fetch materials on mount
    fetchMaterialesTotales();
    fetchMateriales();
    // Handle session expiration
    const iat = user?.iat;
    const exp = user?.exp;
    const now = Date.now() / 1000;
    if (iat && exp && now > exp) {
      setUser({});
    }
  }, [user, setUser]);

  return (
    <Flex direction="column">
      <Navbar materialesTotales={materialesTotales} />
      <Routes>
        <Route
          path="/"
          element={<Main materialesTotales={materialesTotales} />}
        />

        <Route
          path="/materiales"
          element={
            <Materials
              materiales={materiales}
              fetchMoreData={fetchMateriales}
              hasMore={hasMore}
              page={page}
            />
          }
        />

        <Route
          path="/materiales/:type"
          element={
            <Materials
              materiales={materiales}
              fetchMoreData={fetchMateriales}
              hasMore={hasMore}
              page={page}
            />
          }
        />

        <Route
          path="/materiales/:type/:nombre"
          element={<Material materiales={materiales} />}
        />

        <Route path="/what-students-say" element={<WhatStudentsSay />} />

        <Route path="/shop" element={<Shop />} />

        <Route path="/login" element={<Login />} />

        <Route path="/why-me" element={<WhyMe />} />

        <Route path="/get-started" element={<GetStarted />} />

        <Route path="/book-a-lesson" element={<BookLesson />} />

        <Route path="/subscribe" element={<Subscribe />} />

        <Route path="/cancel" element={<Cancel />} />

        <Route path="/success" element={<SuccessPaymentPage />} />

        <Route
          path="/success-subscription"
          element={<SuccessSubscriptionPage />}
        />

        <Route path="/inmersion-program" element={<Inmersion />} />
      </Routes>

      <Footer />
    </Flex>
  );
}

export default App;
