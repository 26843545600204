import BoxInfoTemplate from "../BoxInfoTEmplate/BoxInfoTemplate";

const SuccessPaymentPage = () => {
  return (
    <BoxInfoTemplate
      title="COMPRA COMPLETADA"
      subTitle="COMPRA COMPLETADA"
      mainText="Success! Purchase completed. Gracias amig@!! Book a lesson y Nos vemos en clase! 😀."
      iconType="check"
      borderColor="green"
      showQuotes={false}
    />
  );
};

export default SuccessPaymentPage;
