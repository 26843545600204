import {
  Box,
  Button,
  Divider,
  FormControl,
  Input,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useTheme } from "@emotion/react";
import { useState } from "react";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const { colors } = useTheme();

  const isValidEmail = () => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setLoading(true);

      const response = await fetch("/api/users", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email,
          fecha: new Date(),
        }),
      });

      const data = await response.json();
      console.log(data);

      setEmail("");
      setShowMessage(true);
      setLoading(false);
    } catch (error) {
      console.error("Error submitting the form:", error);
      setLoading(false);
      return;
    }

    setTimeout(() => {
      setShowMessage(false);
    }, 3000);
  }

  return (
    <Box
      bg={useColorModeValue("white", colors.dark.bgPrimary)}
      color={useColorModeValue("gray.600", "white")}
      p="4"
      textAlign="center"
      position="relative"
    >
      <Divider my="4" borderColor={useColorModeValue("gray.300", "gray.600")} />

      <Text fontSize="md" mt="2">
        Join the school mailbox!
      </Text>

      <Box display="inline-block" maxW="300px" mx="auto" p="4">
        {showMessage && (
          <Box
            mb="2"
            bgColor="green"
            color="white"
            p="2"
            mt="2"
            borderRadius="md"
            textAlign="center"
          >
            <Text fontSize="sm">Email sent! Check your inbox.</Text>
          </Box>
        )}
        <form onSubmit={handleSubmit}>
          <FormControl mb="2">
            <Input
              value={email}
              onChange={(e) => {
                handleEmailChange(e);
                setEmail(e.target.value);
              }}
              type="email"
              placeholder="Your email address"
              p="2"
            />
          </FormControl>
          {email && !isValidEmail() && (
            <Text fontSize="sm" color="red">
              Please enter a valid email address
            </Text>
          )}

          <Button
            mb={4}
            type="submit"
            colorScheme="green"
            color="green"
            _hover={{
              bg: "green.500",
              color: "white",
            }}
            isDisabled={!isValidEmail()}
            isLoading={loading}
            loadingText="Conjugando verbos..."
            variant="outline"
            spinner={<Spinner size="sm" />}
            spinnerPlacement="end"
          >
            Join
          </Button>
        </form>
        <Text fontSize="sm">
          &copy; {new Date().getFullYear()} Spanish with Alex. © All rights
          reserved.
        </Text>
      </Box>

      <Box
        position="absolute"
        bottom="4"
        right="4"
        display="flex"
        gap="2"
        alignItems="center"
        fontSize="sm"
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png"
          alt="Visa"
          style={{ width: "30px", height: "auto" }}
        />
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/0/04/Mastercard-logo.png"
          alt="MasterCard"
          style={{ width: "30px", height: "auto" }}
        />
      </Box>
    </Box>
  );
};

export default Footer;
