import { Box, Button, Link } from "@chakra-ui/react";
import Layout from "../../containers/Layout";
import VideoTemplate from "../VideoTemplate/VideoTemplate";

const mainTextContent = `

I founded SWA to help students like you achieve their Spanish language goals. Since then, I’ve guided 100+ learners on their journey to mastering Spanish, whether they’re preparing to relocate, ace an exam, or simply enjoy their next holiday in a Spanish-speaking country.

My approach? Efficient, engaging, and fun. Forget about boring textbooks or endless theory! With me, you’ll use Spanish in the real world from day one.

Now, let’s get learning!

`;

// Componente CustomLink sin el prop 'isExternal' para abrir los enlaces en la misma página

const WhyMe = () => {
  return (
    <Layout mt={10}>
      <VideoTemplate
        imageUrl="/images/titulos.jpg"
        imageAlt="Descripción alternativa de la imagen"
        mainTitle="Engaging, Personalised, and Fun: Your Route to Mastering Spanish!"
        subTitle=""
        mainText={mainTextContent}
        linkPath="https://www.italki.com/en/teacher/6018279"
        showButton={false}
        buttonLabel="Reviews on Italki"
      />
      <Box mt={6}>
        <Button
          ml={3}
          as="a"
          href="/what-students-say"
          target="_blank"
          rel="noopener noreferrer"
          colorScheme="red"
          variant="outline"
          size="md"
        >
          Video Reviews by Students
        </Button>
        <Button
          ml={3}
          as="a"
          href="https://www.italki.com/en/teacher/6018279"
          target="_blank"
          rel="noopener noreferrer"
          colorScheme="red"
          variant="outline"
          size="md"
        >
          Reviews on Italki
        </Button>
      </Box>
    </Layout>
  );
};

export default WhyMe;
