import {
  Divider,
  Flex,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import Layout from "../../containers/Layout";
import CustomButton from "../commons/CustomButton/CustomButton";
import HeadingSection from "../commons/HeadingSection/HeadingSection";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";

const HorizontalCardPrices = () => {
  const { colors } = useTheme();

  return (
    <Layout>
      <HeadingSection title="  " sub="" />
      <Flex
        mb={12}
        flexDirection={{ base: "column", md: "row" }}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        boxShadow="2xl"
        justifyContent="space-around"
        alignItems="center"
        bg={useColorModeValue(
          colors.light.bgSecondary,
          colors.dark.bgSecondary
        )}
        borderColor={useColorModeValue(colors.light.border, colors.dark.border)}
        p="8"
      >
        <Flex direction="column" alignItems={"center"}>
          <Text fontSize="8xl" color="orange">
            $0
          </Text>
          <Text fontWeight="bold" mt={1}>
            {/* per month */}
          </Text>
        </Flex>
        <Flex my={{ base: "12", md: "0" }} direction="column">
          <VStack align="start" spacing={4}>
            <Text> 📌 Access to Exclusive content</Text>
            <Divider
              borderColor={useColorModeValue(
                colors.light.border,
                colors.dark.border
              )}
              borderWidth="1px"
              height="auto"
            />
            <Text>📌 Access all Webminars</Text>
            <Divider
              borderColor={useColorModeValue(
                colors.light.border,
                colors.dark.border
              )}
              borderWidth="1px"
              height="auto"
            />
            <Text>📌 Access the Telegram group</Text>
          </VStack>
        </Flex>
        <Flex direction="column">
          <Link to="/login">
            <CustomButton label="Subscribe" />
          </Link>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default HorizontalCardPrices;
