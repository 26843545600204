import Layout from "../../containers/Layout";
import BoxInfoTemplate from "../BoxInfoTEmplate/BoxInfoTemplate";
import DualTextVideoTemplate from "../DualTextVideoTemplate/DualTextVideoTemplate";

const WhatStudentsSay = () => {
  return (
    <Layout mt={16}>
      <DualTextVideoTemplate
        mainTitle="WHAT MY STUDENTS HAVE TO SAY ABOUT ME"
        buttonLabel="Telegram Group"
        linkPath="https://t.me/+S78OquqBTZdmNGNk"
        showButton={false}
        leftText=""
        rightText=""
        videoUrl="https://iframe.mediadelivery.net/play/245875/8176a8eb-8b8c-4e3a-b428-2e821db8be90"
      />
      <DualTextVideoTemplate
        mainTitle=""
        buttonLabel="Telegram Group"
        linkPath="https://t.me/+S78OquqBTZdmNGNk"
        showButton={false}
        leftText=""
        rightText=""
        videoUrl="https://iframe.mediadelivery.net/play/245875/bb588b38-3c43-4448-9759-849c108aef45"
      />

      <DualTextVideoTemplate
        mainTitle=""
        buttonLabel="Telegram Group"
        linkPath="https://t.me/+S78OquqBTZdmNGNk"
        showButton={false}
        leftText=""
        rightText=""
        videoUrl="https://iframe.mediadelivery.net/play/245875/7dd5d9a0-f3cd-4748-b027-23307af5bcf1"
      />

      {/* <BoxInfoTemplate
        title=""
        subTitle="Milestone achieved!"
        mainText="You did it! Congratulations on completing the challenge. 💪"
        iconType="redDot"
        showQuotes={true}
        showShadow={true}
        borderColor="orange"
      />
      <BoxInfoTemplate
        title="CRITICAL ERROR"
        mainText="Nunca digas: (estoy caliente)."
        iconType="alert"
        borderColor="red"
      />
      <BoxInfoTemplate
        title=""
        mainText="Operación completada."
        iconType="check"
        borderColor="green"
        showQuotes={false}
      />
      <BoxInfoTemplate
        title=""
        mainText="Día a día, verbo a verbo"
        iconType="thumbUp"
        borderColor="blue"
      />
      <BoxInfoTemplate
        title=""
        mainText="New features released."
        iconType="alarm"
        borderColor="brown"
      />
      <BoxInfoTemplate
        title=""
        mainText="Level up!"
        iconType="trophy"
        borderColor="gold"
      />
      <BoxInfoTemplate
        title="CRITICAL ERROR"
        mainText="No digas: (estoy caliente) "
        iconType="alarm"
        borderColor="red"
        showQuotes={false}
      />
      <BoxInfoTemplate
        title=""
        mainText="Great! You nailed it! Keep it up."
        iconType="thumbUp"
        borderColor="blue"
        showQuotes={false}
      />
      <BoxInfoTemplate
        title="ATENCION"
        mainText=" (es bien) No existe en español."
        iconType="alarm"
        borderColor="red"
        showQuotes={false}
      />
      <BoxInfoTemplate
        title=""
        mainText="Pasito a pasito hacemos camino. No te preocupes si vamos despacito."
        iconType="redDotGafasSonrisa"
        borderColor="orange"
        showQuotes={true}
      />
      <BoxInfoTemplate
        title=""
        mainText="Equivocarse es bonito"
        iconType="lightning"
        borderColor="orange"
      />

      <BoxInfoTemplate
        title=""
        mainText="Ser inexpresivo es poco comunicativo."
        iconType="meh"
        borderColor="gray"
        showQuotes={true}
      />
      <BoxInfoTemplate
        title="Celebrate!"
        mainText="New milestone achieved."
        iconType="party"
        borderColor="pink"
      />
      <BoxInfoTemplate
        title="Update Required"
        mainText="Please update your software."
        iconType="thumbDown"
        borderColor="teal"
      />
      <BoxInfoTemplate
        title=""
        mainText="La mejor politica es la del poco a poco."
        iconType="info"
        borderColor="cyan"
      />
      <BoxInfoTemplate
        title=""
        mainText="Please check your email."
        iconType="battery"
        borderColor="orange"
      />
      <BoxInfoTemplate
        title="Deadline!"
        mainText="Submission due today."
        iconType="sun"
        borderColor="red"
      />
      <BoxInfoTemplate
        title="New Message"
        mainText="You have received a new message."
        iconType="check"
        borderColor="blue"
      />
      <BoxInfoTemplate
        title="Security Alert!"
        mainText="Potential security breach detected."
        iconType="shield"
        borderColor="black"
      />
      <BoxInfoTemplate
        title="File Uploaded"
        mainText="Your file was successfully uploaded."
        iconType="upload"
        borderColor="green"
      />
      <BoxInfoTemplate
        title="TEMPERATURE WARNING"
        mainText="Alert! High temperature detected. Drink some water"
        iconType="alert"
        borderColor="red"
        showQuotes={false}
        showShadow={false}
      /> */}
    </Layout>
  );
};

export default WhatStudentsSay;
