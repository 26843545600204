import Layout from "../../containers/Layout";
import BoxInfoTemplate from "../BoxInfoTEmplate/BoxInfoTemplate";
import { Image } from "@chakra-ui/react";
// If DualTextVideoTemplate is not used here, feel free to remove its import.
// import DualTextVideoTemplate from "../DualTextVideoTemplate/DualTextVideoTemplate";

const Inmersion = () => {
  return (
    <Layout mt={16}>
      <BoxInfoTemplate
        title=""
        subTitle="2025 INMERSION PROGRAM!"
        mainText="Discover Galicia with us and learn Spanish,  in Spain!"
        iconType="check"
        showQuotes={false}
        showShadow={true}
        borderColor="green"
      />

      {/* Container for the email text, left-aligned */}
      <div className="email-container" style={{ textAlign: "left" }}>
        <h1 className="email-header" style={{ marginBottom: "1rem" }}>
          Hola amigos y amigas! 😃
        </h1>
        <div className="email-content">
          <p style={{ marginBottom: "1rem" }}>
            We are putting together a team of Spanish teachers in the Galicia
            who will help you discover this region while you learn Spanish.
          </p>

          <p style={{ marginBottom: "1rem" }}>
            In our upcoming Spanish immersion program, we’ll focus on practicing
            Spanish in real-life contexts while exploring the rich culture of
            Galicia, land of stunning coastlines, excellent cuisine, and
            breathtaking hiking routes. Through daily activities, we’ll connect
            with both the language and the culture in a supportive and immersive
            environment.
          </p>

          <p style={{ marginBottom: "1rem" }}>
            I’m truly excited to invite you to this immersive adventure in 2025.
            I believe that online lessons are an excellent method, but having
            the opportunity to test your Spanish in real situations will give
            you that extra motivational push to keep speaking.
          </p>

          <p style={{ marginBottom: "1rem" }}>
            I’m going to refer you to my colleague in Muxía. Her name is Sarah,
            and she is a fantastic teacher. However, if you’d like to explore my
            area, you’re also very welcome! Sign up for the newsletter to
            contact me.
          </p>

          {/* Example Images */}
          <Image
            src="https://www.esys.org/rev_info/Spanien/Muxia_(Gallicien)_Lage_(wiki).jpg"
            alt="Discover Galicia"
            maxWidth="400px"
            marginTop="1rem"
            marginBottom="1rem"
          />
          <Image
            src="https://www.chemins-compostelle.com/sites/default/files/cartes/Carte%20zoon%20-%20Camino%20Fisterra-04.png"
            alt="Sarah Blanquet"
            maxWidth="200px"
            marginBottom="1rem"
          />

          <p>
            <strong>Upcoming Dates:</strong>
          </p>
          <ul>
            <li>
              <strong>May 16th to May 23rd, 2025</strong> - Día das Letras
              Galegas (4 spaces available)
            </li>
            <li>
              <strong>June 17th to June 24th, 2025</strong> - Summer Solstice (6
              spaces available)
            </li>
            <li>
              <strong>September 18th to 25th, 2025</strong> - Late Summer (3
              spaces available)
            </li>
          </ul>

          <p className="signature" style={{ marginTop: "1rem" }}>
            Join the mailing list to contact me!
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Inmersion;
